export function getApplicableUsps(
  usps: string[],
  preOwnedUsps: string[],
  isPreOwned: boolean,
  productSpecificUsps?: string[],
) {
  if (productSpecificUsps && productSpecificUsps.length > 0) {
    return productSpecificUsps
  } else if (isPreOwned) {
    return preOwnedUsps
  }
  return usps
}
