// @ts-strict-ignore
import { SpecialSpan, OldText } from '@gassan-ui'
import { Article } from '@generated'
import { formatPrice } from '@lib/formatPrice'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

const Price: FC<Article> = (props) => {
  const { t } = useTranslation('product-detail')
  const formattedPrice = formatPrice(props.price, true)
  const isRing = props.articleAttributes
    ? props.articleAttributes.findIndex(
        (attr) => attr.attributeID === 15 && attr.attributeValue === 'Ring',
      ) > -1
    : false
  return props.isPriceVisible ? (
    <>
      {props.salePrice && (
        <SpecialSpan
          as="div"
          variant="two"
          display="block"
          mt={{ _: '0.5rem', small: '1rem', large: '1.25rem' }}
          style={{ textDecoration: 'line-through' }}
        >
          {formattedPrice}
        </SpecialSpan>
      )}
      <SpecialSpan
        as="div"
        variant="one"
        display="block"
        mt={{ _: '0.5rem', small: '1rem', large: '1.25rem' }}
        color={props.isOutOfCollection ? 'shades.500' : 'shades.800'}
      >
        {getPrice(
          props.brand,
          props.inStock,
          t('priceOnRequest'),
          props.salePrice,
          props.isWebshopSale,
          formattedPrice,
        )}
      </SpecialSpan>
      {isRing && (
        <OldText variant="small" color="shades.500" as="span">
          Definitieve prijs afhankelijk van maatvoering.
        </OldText>
      )}
    </>
  ) : null
}

function getPrice(
  brand: string,
  inStock: boolean,
  message: string,
  salePrice: number,
  isWebshopSale: boolean,
  price: string,
): null | string {
  if (brand === 'TROPHY BY GASSAN' && !inStock) {
    return message
  }
  if (brand === 'TROPHY BY GASSAN' && !isWebshopSale) {
    return message
  }
  if (salePrice) {
    return formatPrice(salePrice, true)
  } else {
    return price
  }
}

export default Price
