// @ts-strict-ignore
import { useScrollToError } from '../hooks/use-scroll-to-error'
import { LinariaBox, Listbox } from '@components/gassan-ui'
import { Text } from '@components/gassan-ui/Typography/Text'
import { linariaTheme } from '@config/theme'
import { colorStrapOptions } from '@lib/colorStrapOptions'
import { cx } from '@linaria/core'
import { useTranslation } from 'next-i18next'
import React, { FC, useRef } from 'react'

type Props = {
  onSelectColor: (color: string) => void
  selectedColor: string
  colorHasError: boolean
  displayBorder: boolean
}
type Color = {
  label: string
  value: string
  prefix: React.ReactChild
}

export const SelectColor: FC<Props> = ({
  onSelectColor,
  selectedColor,
  colorHasError,
  displayBorder,
}) => {
  const { t } = useTranslation(['product-detail', 'other'])
  const colorItems = getColors()
  const ref = useRef<HTMLDivElement>(null)
  useScrollToError(ref, colorHasError)

  return (
    <>
      <div className="w-full" ref={ref}>
        <Text variant="small" color={colorHasError ? 'error' : 'inherit'} as="div" className="mb-2">
          {t('pickAColorTitle', { ns: 'other' })}
        </Text>
        <div
          className={cx(
            displayBorder && 'border-b border-solid border-shade-200 pb-4',
            'align-mobile-listbox-content-top',
          )}
        >
          <Listbox
            value={selectedColor}
            onChange={(color) => onSelectColor(color)}
            items={colorItems}
            hasError={colorHasError}
          >
            {selectedColor === null ? (
              <LinariaBox as="span" color="shade500">
                {t('pickAColor', { ns: 'other' })}
              </LinariaBox>
            ) : selectedColor === 'dont-know' ? (
              <LinariaBox as="span">{t('iDontKnow', { ns: 'other' })}</LinariaBox>
            ) : (
              <div className="flex items-center gap-2">
                <span
                  className="block h-4 w-4 rounded-full"
                  style={{
                    backgroundColor: colorStrapOptions[selectedColor],
                    border:
                      colorStrapOptions[selectedColor] === '#ffffff' &&
                      `1px solid ${linariaTheme.colors.shade300}`,
                  }}
                />
                {t(`colors.${selectedColor}`)}
              </div>
            )}
          </Listbox>
        </div>
      </div>
    </>
  )

  function getColors() {
    let colors: Color[] = []
    const colorKeys = Object.keys(colorStrapOptions)

    for (const key of colorKeys) {
      colors.push({
        label: t(`colors.${key}`),
        value: key,
        prefix: (
          <span
            className="block h-4 w-4 rounded-full"
            style={{
              backgroundColor: colorStrapOptions[key],
              border:
                colorStrapOptions[key] === '#ffffff' && `1px solid ${linariaTheme.colors.shade300}`,
            }}
          />
        ),
      })
    }

    return colors
  }
}
