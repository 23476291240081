import { RefObject, useEffect } from 'react'

export function useScrollToError(ref: RefObject<HTMLDivElement>, hasError: boolean) {
  useEffect(() => {
    if (hasError) {
      // Check if the ref is in view, don't scroll to it
      if (ref.current && ref.current.getBoundingClientRect().top < 0) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
    }
  }, [hasError, ref])
}
