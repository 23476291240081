import { Listbox, Span } from '@components/gassan-ui'
import { useTranslation } from 'next-i18next'
import React, { FC } from 'react'

const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]

type SelectInitialProps = {
  value: string | null
  handleChange: (value: string) => void
  hasError: boolean
}

export const SelectInitial: FC<SelectInitialProps> = ({ value, handleChange, hasError }) => {
  const { t } = useTranslation('other')
  return (
    <div className="w-full">
      <Listbox
        hasError={hasError}
        value={value as string}
        onChange={(character) => handleChange(character)}
        items={alphabet.map((item) => ({ label: item, value: item }))}
      >
        {value === null ? <Span color="shades.500">{t('select')}</Span> : <>{value}</>}
      </Listbox>
    </div>
  )
}
