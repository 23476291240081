// @ts-strict-ignore
import AddToCart from '../../AddToCart'
import { getApplicableUsps } from '../helpers/get-applicable-usps'
import { useProductInformation } from '../hooks/use-product-information'
import { ChoicesProductAction } from './ChoicesProductAction'
import { Notice } from './Notice'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { Box, breakpoints, Button, OldText } from '@gassan-ui'
import { Article } from '@generated'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import styled from 'styled-components'

const ButtonGrid = styled(Box)`
  grid-column-gap: 1.5rem;
  grid-row-gap: 0.75rem;
  margin-top: 0.5rem;
  display: grid;
  @media screen and (min-width: ${breakpoints.small}) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1;
    margin-top: 1rem;
  }
  @media screen and (min-width: ${breakpoints.large}) {
    grid-template-columns: 1fr;
    margin-top: 1.25rem;
  }

  button {
    width: 100%;
  }
`

type Props = Article & {
  productSpecificUsps?: string[]
  handleMakeAppointment: () => void
  handleGetInContact: () => void
  hideMainCtaMobile: boolean
}

const Buttons: FC<Props> = ({
  productSpecificUsps,
  handleMakeAppointment,
  handleGetInContact,
  hideMainCtaMobile,
  ...rest
}) => {
  const { t } = useTranslation(['navigation', 'other', 'product-detail'])
  const { buttons, title, label, usps } = useProductInformation(rest)

  const isJewel = rest.articleAttributes.find((type) => type.attributeID === 15)
  const isRing = isJewel ? isJewel.attributeValueID === 1 : false
  const isPreOwned = rest.webID.replace(/\s+/g, '') === 'gpcpreown'
  const isChoicesProduct = rest.brand === 'Choices by DL'

  // only display the gift with purchase notice if the product is a Choices or Gigi product and the user can order it
  const showChoicesGiftWithPurchase =
    rest.brand === 'Choices by DL' && !!buttons.find((b) => b === 'order')

  // let applicableUsps = getApplicableUsps(usps, preOwnedUsps, isPreOwned, productSpecificUsps)
  // Temporary fix for not being able to load the pre-owned usps
  let applicableUsps = getApplicableUsps(usps, usps, isPreOwned, productSpecificUsps)

  return (
    <>
      {isChoicesProduct && <Notice variant="choices" />}
      {title && label && <Notice variant="default" title={title} label={label}></Notice>}

      {showChoicesGiftWithPurchase && (
        <ChoicesProductAction brand={rest.brand} price={rest.price ?? 0} />
      )}
      {isPreOwned && (
        <>
          <OldText mb="2">
            <LocalizedLink href={t('submitWatch', { ns: 'navigation' })}>
              <a>{t('swapOrTradeWatch', { ns: 'other' })}</a>
            </LocalizedLink>
          </OldText>
          <OldText>
            <LocalizedLink href={`${t('faq-page-pre-owned', { ns: 'navigation' })}`}>
              <a>{t('faq', { ns: 'other' })}</a>
            </LocalizedLink>
          </OldText>
        </>
      )}
      <ButtonGrid>
        {buttons.map((type, index) => {
          if (type === 'order') {
            return (
              <AddToCart
                variant={isRing ? 'ring' : 'regular'}
                key={type}
                articleId={rest.id}
                hideMobileView={hideMainCtaMobile}
              />
            )
          }
          if (type === 'appointment') {
            return (
              <Button
                key={type}
                variant={index === 0 ? 'dark' : 'outline'}
                onClick={handleMakeAppointment}
              >
                {t('makeAppointment', { ns: 'product-detail' })}
              </Button>
            )
          }
          if (type === 'contact') {
            return (
              <Button
                key={type}
                variant={index === 0 ? 'dark' : 'outline'}
                onClick={handleGetInContact}
              >
                {t('getInContact', { ns: 'product-detail' })}
              </Button>
            )
          }
          return null
        })}
      </ButtonGrid>
      <OldText mb="0" mt="1.5rem" as="div">
        <ul>
          {applicableUsps &&
            applicableUsps.length > 0 &&
            applicableUsps.map((usp, index) => <li key={`usp_${index}`}>{usp}</li>)}
        </ul>
      </OldText>
    </>
  )
}

export default Buttons
