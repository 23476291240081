import { CleanButton, Icon, breakpoints, linkStyles, small, theme } from '@components/gassan-ui'
import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Backdrop = styled(motion.div)`
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  cursor: pointer;
  z-index: ${(p) => p.theme.zIndices.mobileFilters.backdrop};
`

export const Content = styled(motion.div)`
  position: fixed;
  bottom: 0;
  height: auto;
  max-height: 80%;
  width: 100%;
  background: white;
  z-index: ${(p) => p.theme.zIndices.mobileFilters.confirmButton};
  cursor: initial;
  display: grid;
  grid-template-rows: 3.5rem 1fr 4.5rem;
  grid-auto-flow: row;
`

export const InfoLink = styled.a`
  ${small};
  ${linkStyles};
  color: ${(p) => p.theme.colors.shades[500]};
`

export const Check = styled(Icon)`
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`

export const Size = styled(CleanButton)<{ status: 'available' | 'selected' }>`
  width: 100%;
  padding: 0.25rem 0;
  color: ${(p) => p.theme.colors.bigStone};
  border-bottom: 1px solid ${theme.colors.shades[300]};
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-of-type {
    border-bottom: none;
  }

  ${(p) =>
    p.status === 'selected' &&
    `
    ${Check} {
      opacity: 1;
    }
  `}

  @media screen and (min-width: ${breakpoints.small}) {
    width: auto;
    height: 4rem;
    padding: 0 1.25rem;
    border-radius: 4.5rem;
    border: 1px solid ${theme.colors.shades[300]};
    text-align: center;
    display: inline-flex;
    justify-content: center;
    margin-right: 1.25rem;
    margin-bottom: 1.25rem;
    position: relative;

    &:last-of-type {
      border: 1px solid ${theme.colors.shades[300]};
    }

    ${Check} {
      position: absolute;
      top: -0.75rem;
      right: -0.75rem;
      opacity: 0;
    }

    ${(p) =>
      p.status === 'selected' &&
      `
      border-color: ${theme.colors.bigStone};
      ${Check} {
        opacity: 1;
      }
    `}
  }
`
