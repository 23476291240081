export function getChoicesNoticeDescription(originalString: string, lang: string): string {
  let newString = originalString

  if (lang === 'nl') {
    newString = newString.replace(
      'website van Choices by DL',
      `<a href="https://www.choicesbydl.nl/nl" target="_blank">website van Choices by DL</a>`,
    )
  }

  if (lang === 'de') {
    newString = newString.replace(
      'Website von Choices by DL',
      `<a href="https://www.choicesbydl.nl/en" target="_blank">Website von Choices by DL</a>`,
    )
  }

  newString = newString.replace(
    'Choices by DL website',
    `<a href="https://www.choicesbydl.nl/en" target="_blank">Choices by DL website</a>`,
  )

  return newString
}
