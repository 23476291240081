import slugify from 'slugify'

export function getArticleCategoryHref(brand: string, articleType: string, language: string) {
  let categoryHref = `/${slugify(brand, { lower: true })}`
  if (brand.toLowerCase() === 'chopard') {
    if (articleType === 'Horloge') {
      categoryHref = language === 'en' ? '/chopard-watches' : '/chopard-horloges'
    } else {
      categoryHref = language === 'en' ? '/chopard-jewelry' : '/chopard-juwelen'
    }
  }

  if (brand.toLowerCase() === 'pre-owned watches') {
    categoryHref = language === 'nl' ? '/pre-owned-horloges' : '/pre-owned-watches'
  }
  return categoryHref
}
