import Slide from './Slide'
import { CleanButton, Flex, Icon, SliderIndicators } from '@gassan-ui'
import { imgix } from '@lib/imigx'
import Portal from '@reach/portal'
import { motion } from 'framer-motion'
import { FC, useEffect, useState } from 'react'
import { useSwipeable } from 'react-swipeable'

type Props = {
  images: string[]
  title: string
  initialIndex: number
  onClose: () => void
}

const FullscreenGallery: FC<Props> = ({ images, initialIndex, onClose, title }) => {
  const [index, setIndex] = useState(initialIndex)

  const prev = () => (index === 0 ? setIndex(images.length - 1) : setIndex((index) => index - 1))
  const next = () => (index === images.length - 1 ? setIndex(0) : setIndex((index) => index + 1))

  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => prev(),
    preventDefaultTouchmoveEvent: true,
  })

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
      if (e.key === 'ArrowLeft') {
        prev()
      }
      if (e.key === 'ArrowRight') {
        next()
      }
    }
    window.addEventListener('keyup', handleKeyUp)
    return () => {
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [index]) // eslint-disable-line

  return (
    <Portal>
      <motion.div
        className="fixed top-0 z-30 flex h-full w-full items-center bg-shade-50 "
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        {...handlers}
      >
        <div className="absolute top-0 z-[5] flex h-[50px] w-full items-end px-2 sm:h-20 sm:px-4 lg:h-[110px] lg:px-8">
          <div className="flex w-full items-center justify-between">
            <CleanButton className="text-shade-500 hover:text-shade-800" onClick={onClose}>
              <Icon icon="cross"></Icon>
            </CleanButton>

            {images.length > 1 && (
              <Flex>
                <CleanButton className="text-shade-500 hover:text-shade-800" onClick={prev}>
                  <Icon size={{ _: '3rem', small: '3rem', large: '3.5rem' }} icon="chevron-left" />
                </CleanButton>
                <CleanButton className="text-shade-500 hover:text-shade-800" onClick={next}>
                  <Icon size={{ _: '3rem', small: '3rem', large: '3.5rem' }} icon="chevron-right" />
                </CleanButton>
              </Flex>
            )}
          </div>
        </div>
        {images.map((src, key) => (
          <Slide
            key={src}
            isActive={key === index}
            placeholderSrc={imgix(src, { width: 50 })}
            variant="fullscreen"
            title={title}
            sources={[
              imgix(src, { width: 768 }),
              imgix(src, { width: 1024 }),
              imgix(src, { width: 1440 }),
              imgix(src, { width: 1920 }),
            ]}
          />
        ))}
        <div className="absolute bottom-0 z-[5] flex w-full justify-center px-10 pb-4 sm:pb-6">
          {images.length > 1 && (
            <SliderIndicators total={images.length} active={index} onClickItem={setIndex} />
          )}
        </div>
      </motion.div>
    </Portal>
  )
}

export default FullscreenGallery
