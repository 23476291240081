// @ts-strict-ignore
import { useRemoveItemFromCart } from '../../../lib/hooks/use-remove-item-from-cart'
import { useRemovePrivilegeItemFromCart } from '../../../lib/hooks/use-remove-privilege-item-from-cart'
import { SelectColor } from './SelectColor'
import { SelectInitials } from './SelectInitials'
import SelectSize from './SelectSize'
import QuickCart from '@components/_product-detail/QuickCart'
import { Button } from '@gassan-ui'
import { cn } from '@lib/cn'
import { useAddArticleToCart } from '@lib/hooks/use-add-article-to-cart'
import { useCart } from '@lib/hooks/useCart'
import { articleIdsWithoutSizeDropdown } from '@lib/kontent/article-ids-without-size-dropdown'
import { useTranslation } from 'next-i18next'
import { FC, useState } from 'react'

const articleIdsWithColorDropdown = [
  '221784',
  '9954515',
  '9954514',
  '9954512',
  '221786',
  '221785',
  '221780',
  '9954516',
  '9954513',
  // Newly added 07-05-2024
  '222430',
  '222431',
  '221780',
  '222427',
  '921003407',
  '921003409',
  '921003413',
  '921003411',
  '921003410',
  '921003408',
  '921002914',
  '921002911',
  '921003468',
  '921002185',
  '921002210',
  '921002212',
  '921002216',
  '921002908',
  '921002910',
  // Added on 10-06-2024
  '222428',
  // Added on 17-06-2024
  '221779',
  '221783',
]
type FieldErrors = 'color' | 'initials' | 'size'

const articlesWithInitials = {
  '221784': 1,
  '221785': 2,
  '221786': 3,
}

type Props = {
  articleId: string
  variant: 'regular' | 'ring'
  hideMobileView?: boolean
}

const AddToCart: FC<Props> = ({ articleId, variant, hideMobileView }) => {
  const [showQuickCart, setShowQuickCart] = useState(false)
  const [size, setSize] = useState<string | null>(null)
  const [color, setColor] = useState<string | null>(null)
  const [initials, setInitials] = useState<string | null>(null)
  const { t } = useTranslation(['other', 'product-detail', 'notifications', 'navigation'])
  const cart = useCart()

  const [showMobileSizePicker, setShowMobileSizePicker] = useState(false)
  const [fieldErrors, setFieldErrors] = useState<FieldErrors[]>([])
  const { handleAddArticleToCart } = useAddArticleToCart(() => setShowQuickCart(true))
  const { status, removeItemFromCart } = useRemoveItemFromCart()
  const { removePrivilegeItemFromCart } = useRemovePrivilegeItemFromCart()

  const showColorDropdown = articleIdsWithColorDropdown.includes(articleId)
  const hideSizeDropdown = articleIdsWithoutSizeDropdown.includes(articleId)
  const initialCount = articlesWithInitials[articleId] ?? 0
  const showInitialsInput = initialCount > 0

  return (
    <>
      <div className="grid grid-flow-row gap-4">
        {variant === 'ring' && !hideSizeDropdown && (
          <SelectSize
            onRequestClose={() => setShowMobileSizePicker(false)}
            onSelectSize={(size) => handleSetSize(size)}
            onConfirm={() => handleConfirmSize()}
            selectedSize={size}
            showMobileSizePicker={showMobileSizePicker}
            sizeHasError={fieldErrors.includes('size')}
          />
        )}

        {showColorDropdown && (
          <SelectColor
            onSelectColor={(color) => handleSetColor(color)}
            selectedColor={color}
            colorHasError={fieldErrors.includes('color')}
            displayBorder={!showInitialsInput}
          />
        )}

        {initialCount > 0 && (
          <SelectInitials
            initialsCount={initialCount}
            handleSetInitials={setInitials}
            hasError={fieldErrors.includes('initials')}
          />
        )}
      </div>
      <Button
        status={status}
        variant="dark"
        data-test-id="add-to-cart-button"
        onClick={() => handleOrderClick()}
      >
        {t('orderOnline', { ns: 'product-detail' })}
      </Button>
      <div
        className={cn(
          'fixed bottom-0 left-0 z-10 w-full border-t border-solid border-shade-200 bg-white px-4 py-3 shadow-top-shadow transition-transform duration-150',
          hideMobileView && 'translate-y-full',
          'md:hidden',
        )}
      >
        <Button status={status} variant="dark" onClick={() => handleOrderClick()}>
          {t('orderOnline', { ns: 'product-detail' })}
        </Button>
      </div>

      {showQuickCart && (
        <QuickCart
          onRequestClose={() => setShowQuickCart(false)}
          handleRemoveItemFromCart={(id) => handleRemoveItemFromCart(id)}
          handleRemovePrivilegeItemFromCart={removePrivilegeItemFromCart}
        />
      )}
    </>
  )

  async function handleRemoveItemFromCart(cartItemId: string) {
    await removeItemFromCart(cartItemId, () => setShowQuickCart(true))

    if (cart.items.length === 1) {
      setShowQuickCart(false)
    }
  }

  async function handleOrderClick() {
    if (hideSizeDropdown) {
      setSize('dont-know')
    }

    if (variant === 'ring' && !hideSizeDropdown) {
      if (window.innerWidth < 1024) {
        setShowMobileSizePicker(true)
      } else {
        size === null
          ? setFieldErrors((prev) => [...prev, 'size'])
          : handleAddArticleToCart({
              articleId,
              size: size || null,
            })
      }
    } else {
      if (showColorDropdown && color === null) {
        setFieldErrors((prev) => [...prev, 'color'])

        return
      }
      if (showInitialsInput && initials === null) {
        setFieldErrors((prev) => [...prev, 'initials'])

        return
      }

      let message = ''
      if (showColorDropdown && color) {
        message = `${color} | `
      }
      if (showInitialsInput && initials) {
        message += `${initials.split('').join(',')}`
      }

      setFieldErrors([])

      handleAddArticleToCart({
        articleId,
        message,
      })
    }
  }

  function handleConfirmSize() {
    setShowMobileSizePicker(false)
    handleAddArticleToCart({
      articleId,
      size: size || null,
    })
  }

  function handleSetSize(size: string) {
    setSize(size)
    setFieldErrors((prev) => prev.filter((error) => error !== 'size'))
  }

  function handleSetColor(color: string) {
    setColor(color)
    setFieldErrors((prev) => prev.filter((error) => error !== 'color'))
  }
}

export default AddToCart
