import { OldHeading, SpecialSpan } from '@gassan-ui'
import { Article } from '@generated'
import Link from 'next/link'
import { FC } from 'react'

type Props = Article & {
  titleHref: string
}

const Title: FC<Props> = ({ titleHref, ...props }) => {
  const isPreOwned = props.webID?.toLowerCase() === 'gpcpreown'

  return (
    <h1>
      <Link href={titleHref}>
        <OldHeading
          data-test-id="product-page-title"
          variant="h1"
          as="a"
          display="block"
          mb={['.25rem', '1rem']}
          color={props.isOutOfCollection ? 'shades.500' : 'shades.800'}
        >
          {isPreOwned ? props.collection : props.brand}
        </OldHeading>
      </Link>
      <SpecialSpan color="shades.500" variant="two">
        {props.description}
      </SpecialSpan>
    </h1>
  )
}

export default Title
