import { ringSizes } from './ringSizes'

export const getRingSizes = () => {
  let sizes = []
  for (let i = 0; i < ringSizes.length; i++) {
    const value = `${ringSizes[i]}mm`
    sizes.push({ label: value, value: value })
  }
  return sizes
}
