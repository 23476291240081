export const colorStrapOptions = {
  champagne: '#F7E7CE',
  white: '#ffffff',
  babypink: '#FFC0CB',
  yellow: '#ffff00',
  orange: '#FFA500',
  neonpink: '#FF00FF',
  lila: '#cc99cc',
  red: '#FF0000',
  fuchsia: '#ca2c92',
  purple: '#800080',
  lime: '#00FF00',
  babyblue: '#87cefa',
  navy: '#000080',
  silver: '#C0C0C0',
  darkgreen: '#006400',
  brown: '#8B4513',
  black: '#000000',
}
