import { getChoicesNoticeDescription } from '../helpers/get-choices-notice-description';
import { Text } from '@components/gassan-ui/Typography/Text';
import { parse } from '@config/theme';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
type DefaultVariant = {
  title: string;
  label: string;
  variant: 'default';
};
type ChoicesVariant = {
  variant: 'choices';
  title?: never;
  label?: never;
};
type Props = DefaultVariant | ChoicesVariant;
export const Notice: FC<Props> = ({
  title,
  label,
  variant
}) => {
  const {
    t,
    i18n: {
      language
    }
  } = useTranslation('other');
  const choicesDescription = getChoicesNoticeDescription(t('choices-product-notice-description'), language);
  return <div className={parent}>
      <Text variant="regular-bold" className="mb-1 text-shade-500">
        {variant === 'default' ? title : t('choices-product-notice-title')}
      </Text>
      <Text color="shade500" className="mb-0" dangerouslySetInnerHTML={{
      __html: variant === 'default' ? label : choicesDescription
    }}></Text>
    </div>;
};
const parent = parse({
  mt: {
    _: '6'
  },
  mb: {
    _: '6'
  },
  p: {
    _: '4',
    large: '6'
  }
}, "p18r1ds2");

require("./Notice.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Notice.tsx");