import Price from './Price'
import Title from './Title'
import Buttons from './buttons'
import { Box, Flex } from '@gassan-ui'
import { Article } from '@generated'
import { FC } from 'react'

type Props = Article & {
  titleHref: string
  handleMakeAppointment: () => void
  handleGetInContact: () => void
  hideMainCtaMobile: boolean
  productSpecificUsps?: string[]
}

const ProductInformation: FC<Props> = ({
  titleHref,
  productSpecificUsps,
  handleMakeAppointment,
  handleGetInContact,
  ...rest
}) => {
  return (
    <Flex width="100%" maxWidth={{ large: '23.5rem' }} height="100%" flexDirection="column">
      <Box mb={{ _: '1.5rem', medium: '2.5rem' }}>
        <Title titleHref={titleHref} {...rest} />
        <Price {...rest} />
      </Box>
      <Box>
        <Buttons
          brand={rest.brand}
          productSpecificUsps={productSpecificUsps}
          handleMakeAppointment={handleMakeAppointment}
          handleGetInContact={handleGetInContact}
          {...rest}
        />
      </Box>
    </Flex>
  )
}

export default ProductInformation
