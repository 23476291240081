import { Article } from '@generated'
import { useTranslation } from 'next-i18next'

export function useProductInformation(article: Article) {
  const { t } = useTranslation('other')

  const uspsDefault = t('uspsDefault', { ns: 'global-items', returnObjects: true }) as string[]
  const uspsAlternative = t('uspsAlternative', {
    ns: 'global-items',
    returnObjects: true,
  }) as string[]

  //
  // 1. Try to return values based on all the very obvious booleans from DAF
  //
  if (article.isOutOfCollection && !article.inStock && !article.ignoreStockCheck) {
    return {
      buttons: ['appointment', 'contact'],
      title: t('outOfStockDiscontinuedTitle'),
      label: t('outOfStockDiscontinuedLabel'),
      usps: uspsAlternative,
    }
  }

  if (!article.inStock && !article.ignoreStockCheck) {
    // This article is not in stock and ignoreStock is false
    return {
      buttons: ['appointment', 'contact'],
      title: t('outOfStockTitle'),
      label: t('outOfStockLabel'),
      usps: uspsAlternative,
    }
  }

  if (!article.isWebshopSale) {
    // This article is not available online
    return {
      buttons: ['appointment', 'contact'],
      title: t('notOnlineAvailableTitle'),
      label: t('notOnlineAvailableLabel'),
      usps: uspsAlternative,
    }
  }

  if (article.isWebShopOnly) {
    // This article is only available
    return {
      buttons: ['order', 'contact'],
      title: t('onlyOnlineAvailableTitle'),
      label: t('onlyOnlineAvailableLabel'),
      usps: uspsAlternative,
    }
  }

  if (!article.isPriceVisible) {
    return {
      buttons: ['appointment', 'contact'],
      title: t('priceUnavailableOnlineTitle'),
      label: t('priceUnavailableOnlineLabel'),
      usps: uspsAlternative,
    }
  }

  //
  // 2. Try to return values based on the delivery settings in the articleAttributes
  //
  const delivery = article.articleAttributes?.find((attr) => attr?.attributeID === 33)
  const articleType = article.articleType === 'Horloge' ? t('watch') : t('jewel')

  if (delivery) {
    let label = ''
    let title = ''
    if (delivery.attributeValue === 'Delivery_4' || delivery.attributeValue === 'Delivery_6') {
      title = t('deliveryTime')
      label = t('productOnDemandDelivery', {
        returnObjects: true,
        productType: articleType,
        weekAmount: delivery.attributeValue === 'Delivery_4' ? '4' : '6',
      })
    }

    if (
      delivery.attributeValue === 'Delivery_4_size' ||
      delivery.attributeValue === 'Delivery_6_size'
    ) {
      title = t('deliveryTime')
      label = t('productSizeStockDelivery', {
        returnObjects: true,
        productType: articleType,
        weekAmount: delivery.attributeValue === 'Delivery_4_size' ? '4' : '6',
      })
    }

    if (
      article.brand &&
      (article.brand.toLowerCase() === 'gassan 121' || article.brand.toLowerCase() === 'gassan 121')
    ) {
      title = t('deliveryTime')
      label = t('productGassan121Delivery')
    }

    return {
      buttons: ['order', 'contact'],
      title: title,
      label: label,
      usps: uspsAlternative,
    }
  }

  return {
    buttons: ['order', 'contact'],
    title: null,
    label: null,
    usps: uspsDefault,
  }
}
