import { Icon, OldText } from '@gassan-ui'
import { useTranslation } from 'next-i18next'

export function ChoicesProductAction({ brand, price }: { brand: string; price: number }) {
  const { t } = useTranslation('other')

  if (price === 0 || brand !== 'Choices by DL') {
    return null
  }

  let text = ''
  if (price / 100 >= 1500) {
    text = t('choicesApplicableGiftsWithPurchase3', { ns: 'other' })
  } else if (price / 100 >= 500) {
    text = t('choicesApplicableGiftsWithPurchase2', { ns: 'other' })
  } else {
    text = t('choicesApplicableGiftsWithPurchase1', { ns: 'other' })
  }

  return (
    <div className="rounded-lg bg-pampasLight px-3 py-3 md:px-5 md:py-4">
      <div className="mb-1 flex items-center gap-1">
        <Icon icon="account-giftcard" size={32} className="text-tabaccoBrown" />
        <OldText variant="regularBold" mb="0" color="tabaccoBrown">
          {t('giftWithPurchaseTitle')}
        </OldText>
      </div>
      <OldText variant="small" mb="0" className="ml-1 !text-[0.875rem]">
        {text}
      </OldText>
    </div>
  )
}
