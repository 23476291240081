import ProductInformation from '@components/_product-detail/ProductInformation'
import { Article } from '@generated'
import { FC } from 'react'
import { rem } from 'polished'
import { Box } from '@components/gassan-ui'
import ProductGallery from '@components/_product-detail/ProductGallery'
import { useWishlist } from '@lib/hooks/use-wishlist'
import { getArticleCategoryHref } from './helpers/get-article-category-href'

type ProductDetailSectionProps = {
  article: Article
  hideMainCtaMobile: boolean
  language: string
  galleryVariant: 'popover' | 'default'
  productSpecificUsps?: string[]
  onOpenContactModal: (type: 'appointment' | 'contact') => void
}

const ProductDetailSection: FC<ProductDetailSectionProps> = ({
  article,
  productSpecificUsps,
  onOpenContactModal,
  hideMainCtaMobile,
  language,
  galleryVariant,
}) => {
  const wishlist = useWishlist()
  const categoryHref = getArticleCategoryHref(article.brand, article.articleType, language)

  return (
    <Box
      as="section"
      width={{
        _: '100%',
        large: 'calc(100% - 3rem)',
        xlarge: 'calc(100% - 5.5rem)',
      }}
      display="grid"
      gridTemplateColumns={{ _: '100%', large: `auto ${rem(360)}`, xlarge: `auto ${rem(420)}` }}
      gridColumnGap={{ large: rem(90), xlarge: rem(120) }}
      mt={{ _: 0, large: '2.5rem' }}
      mb={{ _: 0, large: '3rem' }}
      maxWidth="109rem"
      mx="auto"
    >
      <ProductGallery
        id={article.id}
        images={article.images}
        title={`${article.brand} ${article.description}`}
        onClickWishlist={() =>
          wishlist.toggle({
            id: article.id,
            title: article.brand,
            subtitle: article.description,
            image: article.images[0],
          })
        }
        isOnWishList={wishlist.articleIds.includes(article.id)}
        variant={galleryVariant}
      />
      <Box
        pt={{ _: '1.5rem', small: '3rem', large: 0 }}
        px={{ _: '1rem', small: '2rem', large: 0 }}
      >
        <ProductInformation
          {...article}
          productSpecificUsps={productSpecificUsps}
          titleHref={categoryHref}
          handleMakeAppointment={() => {
            onOpenContactModal('appointment')
          }}
          handleGetInContact={() => {
            onOpenContactModal('contact')
          }}
          hideMainCtaMobile={hideMainCtaMobile}
        />
      </Box>
    </Box>
  )
}

export default ProductDetailSection
