import Mobile from './Mobile'
import LocalizedLink from '@components/Localization/LocalizedLink'
import {
  Box,
  Flex,
  Listbox as ListboxEl,
  Span,
  OldText,
  linkStyles,
  small,
  theme,
} from '@components/gassan-ui'
import { getUrlSlugInLocale } from '@lib/get-url-slug-in-locale'
import { getRingSizes } from '@lib/getRingSizes'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import styled from 'styled-components'

type Props = {
  onRequestClose: () => void
  onSelectSize: (size: string) => void
  onConfirm: () => void
  selectedSize: string
  showMobileSizePicker: boolean
  sizeHasError: boolean
}

const InfoLink = styled.a`
  ${small};
  ${linkStyles};
  color: ${(p) => p.theme.colors.shades[500]};
`

const Listbox = styled(ListboxEl)`
  background: red;
`

const SelectSize: FC<Props> = ({
  onRequestClose,
  onSelectSize,
  onConfirm,
  selectedSize,
  showMobileSizePicker,
  sizeHasError,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('other')

  return (
    <>
      <Box display={{ _: 'none', large: 'block' }} width="100%">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          pb="0.5rem"
          mb="1rem"
          borderBottom={`1px solid ${theme.colors.shades[200]}`}
        >
          <OldText
            variant="small"
            mb="0"
            color={sizeHasError ? theme.colors.error : 'inherit'}
            as="div"
            width="50%"
          >
            {t('pickASize')}
          </OldText>
          <LocalizedLink href={`/${getUrlSlugInLocale('sizeInformation', language)}`}>
            <InfoLink target="_blank">{t('sizeInformation')}</InfoLink>
          </LocalizedLink>
        </Flex>
        <Box
          pb="1rem"
          borderBottom={`1px solid ${theme.colors.shades[200]}`}
          data-test-id="pick-a-size"
        >
          <Listbox
            value={selectedSize}
            onChange={(size) => onSelectSize(size)}
            items={[
              {
                label: t('iDontKnow'),
                value: 'dont-know',
              },
              ...getRingSizes(),
            ]}
            hasError={sizeHasError}
          >
            {selectedSize === null ? (
              <Span color="shades.500">{t('pickASize')}</Span>
            ) : selectedSize === 'dont-know' ? (
              <Span>{t('iDontKnow')}</Span>
            ) : (
              <>{selectedSize}</>
            )}
          </Listbox>
        </Box>
      </Box>
      {showMobileSizePicker && (
        <Mobile
          onRequestClose={onRequestClose}
          onSelectSize={(size) => onSelectSize(size)}
          onConfirm={onConfirm}
          selectedSize={selectedSize}
        />
      )}
    </>
  )
}

export default SelectSize
