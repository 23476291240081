import { useScrollToError } from '../hooks/use-scroll-to-error'
import { SelectInitial } from './SelectInitial'
import { Text } from '@components/gassan-ui/Typography/Text'
import { useTranslation } from 'next-i18next'
import { FC, useEffect, useRef, useState } from 'react'

type SelectInitialsProps = {
  initialsCount: 1 | 2 | 3
  handleSetInitials: (initials: string) => void
  hasError?: boolean
}

export const SelectInitials: FC<SelectInitialsProps> = ({
  initialsCount,
  handleSetInitials,
  hasError,
}) => {
  const { t } = useTranslation('other')
  const [firstInitial, setFirstInitial] = useState<string | null>(null)
  const [secondInitial, setSecondInitial] = useState<string | null>(null)
  const [thirdInitial, setThirdInitial] = useState<string | null>(null)
  const ref = useRef<HTMLDivElement>(null)
  useScrollToError(ref, hasError as boolean)

  const initials = [firstInitial, secondInitial, thirdInitial]
    .filter((initial) => initial !== null)
    .join('')

  useEffect(() => {
    // If all initials are selected, set them
    if (initials?.length === initialsCount) {
      handleSetInitials(initials)
    }
  }, [initials, handleSetInitials, initialsCount])

  return (
    <div ref={ref} className="border-b border-solid border-shade-200 pb-4">
      <Text variant="small" as="div" className="mb-2" color={hasError ? 'error' : 'inherit'}>
        {t('pickInitials', { ns: 'other' })}
      </Text>
      <div className="align-mobile-listbox-content-top flex w-full items-center justify-between gap-2">
        <SelectInitial
          value={firstInitial}
          handleChange={setFirstInitial}
          hasError={(hasError && !firstInitial) as boolean}
        />
        {initialsCount > 1 && (
          <SelectInitial
            value={secondInitial}
            handleChange={setSecondInitial}
            hasError={(hasError && !secondInitial) as boolean}
          />
        )}
        {initialsCount > 2 && (
          <SelectInitial
            value={thirdInitial}
            handleChange={setThirdInitial}
            hasError={(hasError && !thirdInitial) as boolean}
          />
        )}
      </div>
    </div>
  )
}
