// @ts-strict-ignore
import FullscreenGallery from './FullscreenGallery'
import Gallery from './Gallery'
import { BreadcrumbItem } from '@components/Breadcrumbs'
import { useBodyScrollLock } from '@lib/hooks/useBodyScrollLock'
import { FC, useRef, useState } from 'react'

type GalleryProps = {
  id: string
  images: string[]
  title: string
  breadcrumbItems?: BreadcrumbItem[]
  variant?: 'default' | 'popover'
  isOnWishList: boolean
  onClickWishlist: () => void
}

const ProductGallery: FC<GalleryProps> = ({
  id,
  images,
  title,
  breadcrumbItems,
  variant = 'default',
  isOnWishList,
  onClickWishlist,
}) => {
  const [initialFullscreenIndex, setInitialFullscreenIndex] = useState<number | null>(null)
  const onOpenFullScreenOnIndex = (index: number) => setInitialFullscreenIndex(index)
  const ref = useRef()
  useBodyScrollLock(ref, initialFullscreenIndex !== null)

  // <div ref={ref}/> is used because refs don't work inside portals and we don't need scrolling here
  return (
    <>
      <Gallery
        id={id}
        title={title}
        images={images}
        breadcrumbItems={breadcrumbItems}
        variant={variant}
        isOnWishList={isOnWishList}
        onClickWishlist={onClickWishlist}
        openFullScreenOnIndex={onOpenFullScreenOnIndex}
      />
      <div ref={ref} style={{ display: 'none' }} />
      {initialFullscreenIndex !== null && (
        <FullscreenGallery
          title={title}
          initialIndex={initialFullscreenIndex}
          images={images}
          onClose={() => setInitialFullscreenIndex(null)}
        />
      )}
    </>
  )
}

export default ProductGallery
