import { Image } from '@components/gassan-ui'
import { cn } from '@lib/cn'
import { FC, HTMLAttributes, useEffect, useRef } from 'react'

type Props = HTMLAttributes<HTMLDivElement> & {
  isActive: boolean
  sources: string[]
  placeholderSrc: string
  variant?: 'default' | 'fullscreen'
  title: string
}

const Slide: FC<Props> = ({
  isActive,
  sources,
  placeholderSrc,
  variant = 'default',
  title,
  onClick,
  ...rest
}) => {
  const hasBeenActive = useRef(false)
  useEffect(() => {
    if (isActive && !hasBeenActive.current) {
      hasBeenActive.current = true
    }
  }, [isActive])

  return (
    <div onClick={onClick}>
      <Image
        {...rest}
        alt={title}
        sources={sources}
        placeholderSrc={placeholderSrc}
        objectFit="contain"
        loading={hasBeenActive.current === true || isActive ? 'lazy' : 'none'}
        className={cn(
          'absolute left-0 top-0 h-full w-full bg-shade-50 transition-opacity duration-300',
          isActive ? 'opacity-100' : 'opacity-0',
          variant === 'default' && 'cursor-zoom-in',
        )}
      />
    </div>
  )
}

export default Slide
