// @ts-strict-ignore
import * as S from './Mobile.elements'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { Box, Button, Flex, OldHeading, OldText, theme } from '@gassan-ui'
import { getUrlSlugInLocale } from '@lib/get-url-slug-in-locale'
import { useBodyScrollLock } from '@lib/hooks/useBodyScrollLock'
import { ringSizes } from '@lib/ringSizes'
import Portal from '@reach/portal'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { FC, useEffect, useRef, useState } from 'react'

type Props = {
  onRequestClose: () => void
  onSelectSize: (size: string) => void
  onConfirm: () => void
  selectedSize: string
}

const Mobile: FC<Props> = ({ onRequestClose, onSelectSize, onConfirm, selectedSize }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('other')
  const [isMounted, setIsMounted] = useState(false)
  const scrollRef = useRef<HTMLDivElement>()

  useEffect(() => {
    setIsMounted(true)
    return () => setIsMounted(false)
  }, [])

  useBodyScrollLock(scrollRef, isMounted)

  return (
    <Portal>
      <Box display={{ _: 'block', large: 'none' }}>
        <AnimatePresence>
          <S.Backdrop
            onClick={onRequestClose}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            key="backdrop"
          />
          <S.Content
            initial={{ opacity: 0, y: 150 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.2, duration: 0.2, ease: 'easeOut' },
            }}
          >
            <Flex
              justifyContent="space-between"
              alignItems="center"
              p={{ _: '1rem', small: '1.5rem 2rem' }}
              borderBottom={`1px solid ${theme.colors.shades[300]}`}
            >
              <OldHeading variant="h3" mb="0">
                {t('pickASize')}
              </OldHeading>
              <LocalizedLink href={`/${getUrlSlugInLocale('sizeInformation', language)}`}>
                <S.InfoLink target="_blank">{t('sizeInformation')}</S.InfoLink>
              </LocalizedLink>
            </Flex>
            <Box
              p={{ _: '0 1rem 1rem', small: '1.5rem 2rem 1.25rem' }}
              overflow="scroll"
              ref={scrollRef}
            >
              <S.Size
                onClick={() => onSelectSize('dont-know')}
                status={'dont-know' === selectedSize ? 'selected' : 'available'}
              >
                <OldText
                  mb="0"
                  as="span"
                  variant={'dont-know' === selectedSize ? 'regularBold' : 'regular'}
                >
                  {t('iDontKnow')}
                </OldText>
                <S.Check icon="check-circle" size={40} />
              </S.Size>
              {ringSizes.map((size, key) => (
                <S.Size
                  onClick={() => onSelectSize(`${size.toString()}mm`)}
                  status={`${size.toString()}mm` === selectedSize ? 'selected' : 'available'}
                  key={key}
                >
                  <OldText
                    mb="0"
                    as="span"
                    variant={`${size.toString()}mm` === selectedSize ? 'regularBold' : 'regular'}
                  >{`${size}mm`}</OldText>
                  <S.Check icon="check-circle" size={40} />
                </S.Size>
              ))}
            </Box>
            <Box
              width="100%"
              p={{ _: '0.5rem 1rem' }}
              borderTop={`1px solid ${theme.colors.shades[300]}`}
              bg="white"
            >
              <Button
                onClick={onConfirm}
                className="w-full"
                variant="dark"
                status={selectedSize !== null ? 'idle' : 'disabled'}
              >
                {t('addToCart')}
              </Button>
            </Box>
          </S.Content>
        </AnimatePresence>
      </Box>
    </Portal>
  )
}

export default Mobile
