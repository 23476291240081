import { BreadcrumbItem, Breadcrumbs } from '@components/Breadcrumbs'
import { CleanButton, Icon, SliderIndicators, OldText, WishListButton } from '@gassan-ui'
import { imgix } from '@lib/imigx'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import Slide from './Slide'

type Props = {
  id: string
  images: string[]
  title: string
  breadcrumbItems?: BreadcrumbItem[]
  variant?: 'default' | 'popover'
  isOnWishList: boolean
  onClickWishlist: () => void
  openFullScreenOnIndex: (index: number) => void
}

const Gallery: FC<Props> = ({
  id,
  images,
  title,
  breadcrumbItems,
  variant = 'default',
  isOnWishList,
  onClickWishlist,
  openFullScreenOnIndex,
}) => {
  const { t } = useTranslation('other')
  const [index, setIndex] = useState(0)
  const router = useRouter()
  const next = () => (index === images.length - 1 ? setIndex(0) : setIndex(index + 1))
  const prev = () => (index === 0 ? setIndex(images.length - 1) : setIndex(index - 1))
  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => prev(),
    preventDefaultTouchmoveEvent: true,
  })

  return (
    <section
      className="bg-shades-50 relative w-full before:block before:pt-[107.5%] before:content-[''] sm:before:pt-[74%] lg:before:pt-[70%]"
      {...handlers}
    >
      <div className="absolute top-0 z-[5] flex w-full justify-between px-4 pt-4 sm:px-8 sm:pt-6 lg:px-10">
        {breadcrumbItems ? (
          <Breadcrumbs items={breadcrumbItems} />
        ) : (
          <div className="hidden lg:block" />
        )}
        {variant === 'default' && (
          <div className="block lg:hidden">
            <CleanButton onClick={router.back}>
              <Icon icon="arrow-left" color="shades.800"></Icon>
            </CleanButton>
          </div>
        )}
        <WishListButton isOnWishlist={isOnWishList} onClick={onClickWishlist}></WishListButton>
      </div>
      {images.length > 1 && (
        <>
          <CleanButton
            className="absolute left-10 top-1/2 z-[5] hidden -translate-y-1/2 text-shade-500 transition-colors duration-100 hover:text-shade-800 lg:block"
            onClick={prev}
          >
            <Icon icon="arrow-left" />
          </CleanButton>
          <CleanButton
            className="absolute right-10 top-1/2 z-[5] hidden -translate-y-1/2 text-shade-500 transition-colors duration-100 hover:text-shade-800 lg:block"
            onClick={next}
          >
            <Icon icon="arrow-right" color="currentColor" />
          </CleanButton>
        </>
      )}
      {images.map((src, key) => (
        <Slide
          key={src}
          title={title}
          isActive={key === index}
          placeholderSrc={imgix(src, { width: 50 })}
          onClick={() => openFullScreenOnIndex(key)}
          sources={[
            imgix(src, { width: 768 }),
            imgix(src, { width: 1024 }),
            imgix(src, { width: 1024 }),
            imgix(src, { width: 1200 }),
          ]}
        />
      ))}
      {images.length > 1 && (
        <div className="z-5 absolute bottom-1 flex w-full justify-center px-10 pb-2 sm:bottom-0 sm:justify-start sm:pb-6">
          <SliderIndicators
            total={images.length}
            active={index}
            onClickItem={(index) => setIndex(index)}
          />
        </div>
      )}
      <OldText
        className="text-shades-400 absolute bottom-3 left-4 mb-0 sm:bottom-6 sm:left-auto sm:right-10 lg:bottom-8"
        mb="0"
        color="shades.400"
        as="span"
      >
        {`${t('articleNumberAbbr')} ${id}`}
      </OldText>
    </section>
  )
}

export default Gallery
